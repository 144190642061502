import React from 'react';
import styles from './chatloading.module.css'
import { LuDot } from 'react-icons/lu';

export const ChatLoading = () => {
  return (
    <div className="flex justify-start">

      {/* FOR OTHER */}
      <img className="w-6 h-6 rounded-[15px] mr-2 mt-3" src={'https://cdn-icons-png.flaticon.com/512/2171/2171074.png'} alt="Avatar" />

      <div className="max-w-[70%] mb-1">
        <div className="rounded-[15px] text-[18px] py-1 px-3 mt-2 bg-gray-700 bg-opacity-60 text-white">
          <span className={`${styles.dotAnimation} text-[30px] `}><div className='h-[5px] w-[5px] rounded-full bg-gray-400 ' ></div></span>
          <span className={`${styles.dotAnimation} text-[30px] `}><div className='h-[5px] w-[5px] rounded-full bg-gray-400 ' ></div></span>
          <span className={`${styles.dotAnimation} text-[30px] `}><div className='h-[5px] w-[5px] rounded-full bg-gray-400 ' ></div></span>
        </div>
      </div>

    </div>
  );
}
