import React, { useEffect, useState }  from 'react'
import { HiMiniMicrophone } from 'react-icons/hi2';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './bubble.css'


export const SpeechRecognisation = ({getTranscript,setAudioSupport}) => {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const handleStartListening = () => {
        resetTranscript(); // Reset transcript before starting
        SpeechRecognition.startListening();
    };

    const handleStopListening = () => {
        
        SpeechRecognition.stopListening();
        getTranscript(transcript); 
    };

    useEffect(() => {
        if (!listening && transcript) {
            handleStopListening();
        }
    }, [listening]);

    
    if (!browserSupportsSpeechRecognition) {
        setAudioSupport(false)
    }
    

  return (
    <div>

        <div
            onClick={handleStartListening}
            className={`flex justify-center cursor-pointer rounded-full bg-primary items-center text-[19px] p-[7px] text-white z-10 relative ${
                listening ? 'recording' : ''
            }`}
        >
            <HiMiniMicrophone />
            {listening && <div className="bubble-effect absolute inset-0"></div>}
        </div>
    </div>
  )
}