import React from 'react'

const ChatBubble = ({isMine,time,message,video}) => {
  
  return (
    <div className={`flex  ${isMine?'justify-end':'justify-start'} `} >
      {/* FOR ME  */}
      {
        isMine && (
          <div className="max-w-[70%] mb-1 ">
            <div className={` rounded-[15px] text-[14px] py-1 px-3 mt-2  bg-primary text-white`}>{message}</div>
            <div className="text-[10px] text-white mt-1 flex justify-end  ">{time}</div>
          </div>
        )
      }

      {/* FOR OTHER */}
      {!isMine && (
        <img className="w-6 h-6 rounded-[15px] mr-2 mt-3" src={'https://cdn-icons-png.flaticon.com/512/2171/2171074.png'} alt="Avatar"/>
      )}
      
      {!isMine && (<div className="max-w-[70%] mb-1 ">
            <div className={` rounded-[15px] text-[14px] py-1 px-3 mt-2  bg-gray-700 bg-opacity-60 text-white`}>{message}</div>
            <div className="text-[10px] text-white mt-1 flex justify-start  ">{time}</div>
          </div>)}

      
    </div>
  )
}
export default ChatBubble


// {!isMine && video  && (
//   <div className="max-w-[70%] mb-1 " >
//     <video className="w-[100%] h-[100%] mr-2 mt-3 object-contain bg-black " src={video} alt="video Avatar"/>
//   </div>
// )}
