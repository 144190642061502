import React from 'react'
import { RxCross2 } from 'react-icons/rx'
export const FileCard = ({heading,subHeading,handleCross}) => {
  const handleCross2 = ()=>{
    handleCross()
  }
  return (
    <div className='w-[120px] h-[80px] laptop:h-[150px] laptop:w-[250px] flex items-center px-[20px] bg-white rounded-2xl absolute right-[10%] z-50 top-1/2 transform -translate-y-1/2 ' >
        <div>
            <h3 className='text-off-black text-[15px] font-[500] ' >{heading} <span className='text-blue-500 underline ' ><a href={subHeading} target="_blank" >link</a> </span></h3>
        </div>
        <div onClick={handleCross2} className='absolute top-2 right-2 text-[19px] laptop:text-[21px] cursor-pointer ' ><RxCross2 /></div>
    </div>
  )
}
