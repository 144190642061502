import react from 'react';
import { AllRoutes } from './routes/AllRoutes';


function App() {
  return (
    <div className='font-redHat  ' >
      <AllRoutes/>
    </div>
  );
}

export default App;
