import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Homepage } from '../pages/Homepage'
import { Login } from '../pages/Login'
import { PrivateRoutes } from './PrivateRoutes'

export const AllRoutes = () => {
  return (
    <Routes>
        <Route path='/' element={<PrivateRoutes><Homepage/></PrivateRoutes>} ></Route>
        <Route path='/login' element={<Login/>} ></Route>
    </Routes>
  )
}
