import React from 'react'
import { Navbar } from '../components/shared/Navbar'
import { Body } from '../components/homepage/Body'
import { BackgroundGradientAnimation } from '../components/ui/background-gradient-animation';
export const Homepage = () => {

  return (
    
    <div>
        <BackgroundGradientAnimation>
            <Navbar/>
            <Body/>
        </BackgroundGradientAnimation>
    </div>
  )
}
