import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { primary_glassmorphism } from '../helpers/glassmorphism'
import { useNavigate } from 'react-router-dom';
import styles from './login.module.css'


export const Login = () => {
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState('')
    const [loading,setLoading] = useState(false)
    const nav = useNavigate()

    const handleLogin = ()=>{
        setLoading(true)
        if(email == 'chat@personate.ai' && password == 'personate@secure'){
            localStorage.setItem('chatLogin', true);
            toast.success('Login successful!');
            setTimeout(() => {
                setLoading(false)
                nav('/');
            }, 3000);
        }else{
            toast.error('Invalid credentials, please try again.');
            setLoading(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission
        handleLogin(); // Call the login function
    };


  return (
    <div className='w-[100%] h-[100%] fixed bg-gradient-to-r from-gradient-start to-gradient-end flex justify-center items-center font-redHat ' >

        {/* LOGIN FORM */}
        <form 
            onSubmit={handleSubmit} 
            className={`w-[85%] laptop:w-[380px] font-redHat px-[20px] py-[50px] rounded-xl flex flex-col ${primary_glassmorphism}`}>
            
            <div className='w-[45%] mx-auto'>
                <img className='w-[100%] object-contain' src="/whiteLogo.png" alt="Persoante logo" />
            </div>
            <h2 className='text-[20px] text-white font-[500] tracking-wider mt-[30px]'>Sign In to Personate!</h2>
            
            <div className='mt-[20px]'>
                <h4 className='text-[14px] text-gray-200 tracking-wider mb-1'>Email</h4>
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className='w-[100%] rounded-md h-[32px] bg-black bg-opacity-30 focus:outline-none focus:ring-[1px] text-[13px] text-white font-[400] px-[10px] tracking-wide'
                />
            </div>
            <div className='mt-[20px]'>
                <h4 className='text-[14px] text-gray-200 tracking-wider mb-1'>Password</h4>
                <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className='w-[100%] rounded-md h-[32px] bg-black bg-opacity-30 focus:outline-none focus:ring-[1px] text-[13px] text-white font-[400] px-[10px] tracking-wide'
                />
            </div>

            <button
                type="submit"
                disabled={loading}
                className={`${styles.loginButton} transition-colors ease-in-out duration-300 h-[32px] bg-primary hover:bg-[#4A148C] mt-[20px] font-[500] rounded-md text-white text-[14px] tracking-wider`}
            >
                {loading ? (<div className={`${styles.loader}`}></div>) : ('Login')}
            </button>
        </form>
        <ToastContainer />
    </div>
  )
}
