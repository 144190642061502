import React from 'react'
import { BsStars } from 'react-icons/bs'
import styles from './debounce.module.css'
import { RxArrowTopRight } from 'react-icons/rx'
import { useSpring, animated } from '@react-spring/web';

export const Debouncing = ({ suggestQuestionPrompts, handleSend, setMessage,setSuggestQuestionPrompts }) => {
    const animationProps = useSpring({
        maxHeight: suggestQuestionPrompts?.length > 0 ? '300px' : '0px',
        // from: { maxHeight: '0px' },
        config: { duration: 500 },
    });

    const handlePromptSend = (value) => {
        setMessage(value);
        handleSend(value);
        setSuggestQuestionPrompts([])
    }

    return (
        <div className={`overflow-hidden ${suggestQuestionPrompts?.length > 0 ? 'rounded-t-[15px] border-b border-gray-600 border-opacity-55 ' : ''} z-50 w-[98%] laptop:w-[50%] left-1/2 transform -translate-x-1/2 bg-black bg-opacity-40 backdrop-blur-lg absolute bottom-0`}>
            <animated.div style={animationProps}>
                <div>
                    {suggestQuestionPrompts?.map((el, i) => (
                        <div
                            key={i}
                            onClick={() => handlePromptSend(el)}
                            className='flex items-center h-[40px] border-b border-gray-600 gap-x-1 px-[10px] text-white cursor-pointer hover:bg-black hover:bg-opacity-35 transition-colors ease-in-out duration-300'
                        >
                            <div className='text-[15px]'><RxArrowTopRight /></div>
                            <div className='text-[15px]'>{el}</div>
                        </div>
                    ))}
                </div>
            </animated.div>
        </div>
    );
}

// {
//     typingLoading && (<div className='flex w-[100%] items-center h-[20px] ' >
//         <div className={`${styles.glow} text-[19px] `} ><BsStars /></div>
//         {/* <div className='h-[100%] z-50 w-[100%] ' ><Skeleton  className='bg-red-500 ' /></div> */}
//     </div>)
// }