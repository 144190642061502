import React from 'react'
import { RxCross2 } from 'react-icons/rx'

export const DateCard = ({heading,subHeading,handleCross}) => {
  const handleCross2 = ()=>{
    handleCross()
  }
  return (
    <div className='w-[140px] h-[100px] laptop:h-[150px] laptop:w-[250px] flex items-center px-[20px] bg-white rounded-2xl absolute right-[10%] z-50 top-[50px] laptop:top-1/2 laptop:transform laptop:-translate-y-1/2 ' >
        <div>
            <h2 className='text-off-black text-[16px] laptop:text-[24px] font-[700]' >{heading}</h2>
            <p className='text-[13px] text-off-black font-[400] ' >{subHeading}</p>
        </div>
        <div onClick={handleCross2} className='absolute top-2 right-2 text-[19px] laptop:text-[21px] cursor-pointer ' ><RxCross2 /></div>
    </div>
  )
}
